import { createApi } from "@reduxjs/toolkit/query/react";
import customFetchBase from "./customFetchBase";

import { ProductSettingSaveInput } from "../../pages/admin/product.page";
import { PriceSettingSaveInput } from "../../pages/admin/price.page";

import {
    ISubscription,
    ICreateSubscription,
    IGetVerifyCouponCode
} from "./types";

export const subscriptionApi = createApi({
  reducerPath: "subscriptionApi",
  baseQuery: customFetchBase,
  tagTypes: ["Subscription"],
  endpoints: (builder) => ({
    createSubscription: builder.query<any, { email: String; price_id: String , stripeToken: String}>({
      query(arg) {
        const { email, price_id, stripeToken } = arg;
        return {
          url: "subscription/create-subscription",
          params: { email, price_id, stripeToken },
          method: "GET",
        };
      },
    }),
    createCheckoutSession: builder.query<any, { emailid: String, username: String, subscription_level: String, referral_param: String, couponcode: String, mode: string, campaignid: string, from_page: string}>({
      query(arg) {
        const { emailid, username, subscription_level, referral_param, couponcode, mode, campaignid, from_page} = arg;
        return {
          url: "subscription/create-checkout-session",
          params: { emailid, username, subscription_level, referral_param, couponcode, mode, campaignid, from_page },
          method: "POST",
        };
      },
    }),
    getOrderSuccess: builder.query<any, {session_id: String, campaign_id: String, subscription_level: String, module: String, utlized_podcast_count: String, utlized_blog_count: String }>({
      query(arg) {
        const { session_id, campaign_id, subscription_level, module, utlized_podcast_count, utlized_blog_count } = arg;
        return {
          url: "subscription/order/success",
          params: { session_id, campaign_id, subscription_level, module, utlized_podcast_count, utlized_blog_count},
          method: "GET",
        };
      },
    }),
    reduceWishCount: builder.query<any, {userid: String}>({
      query(arg) {
        const { userid } = arg;
        return {
          url: "api/prompts/reduceWishCount",
          params: { userid },
          method: "GET",
        };
      },
    }),

    activateSubscription: builder.query<any, { email: String}>({
      query(arg) {
        const { email } = arg;
        return {
          url: "subscription/activate_now",
          params: { email },
          method: "POST",
        };
      },
    }),

    verifySite: builder.query<any, {userid: String, campaignid: String, domain_recieved: String}>({
      query(arg) {
        const { userid, campaignid, domain_recieved} = arg;
        return {
          url: "prompts/track",
          params: { userid, campaignid, domain_recieved },
          method: "POST",
        };
      },
    }),

    verifyCoupon: builder.query<IGetVerifyCouponCode, {coupon_code: string}>({
      query(arg) {
        const {coupon_code} = arg;
        return {
          url: "subscription/validate_coupon_code",
          params: { coupon_code },
          method: "POST",
        };
      },
    }),
  }),
  
});

export const {
  useLazyCreateSubscriptionQuery,
  useLazyCreateCheckoutSessionQuery,
  useGetOrderSuccessQuery,
  useReduceWishCountQuery,
  useActivateSubscriptionQuery,
  useVerifySiteQuery,
  useVerifyCouponQuery
} = subscriptionApi;
